export default [
  {
    value: "name",
    text: "Name",
    togglable: true,
    visible: true,
    width: 200,
    sortable: false,
  },
  {
    value: "exid",
    text: "ID",
    togglable: true,
    visible: true,
    width: 150,
    sortable: false,
  },
  {
    value: "externalHost",
    text: "IP",
    togglable: true,
    visible: true,
    width: 220,
    sortable: false,
  },
  {
    value: "model",
    text: "Model",
    togglable: true,
    visible: true,
    width: 150,
    sortable: false,
  },
  {
    value: "deviceName",
    text: "Device",
    togglable: true,
    visible: true,
    width: 150,
    sortable: false,
  },
  {
    value: "macAddress",
    text: "Mac Address",
    togglable: true,
    visible: true,
    width: 120,
    align: "center",
    sortable: false,
  },
  {
    value: "firmwareVersion",
    text: "Firmware",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "resolution",
    text: "Resolution",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "bitrateType",
    text: "Bitrate Type",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "videoQuality",
    text: "Video Quality",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "frameRate",
    text: "FPS",
    togglable: true,
    visible: true,
    width: 50,
    align: "center",
    sortable: false,
  },
  {
    value: "bitrate",
    text: "Max Bitrate",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "videoEncoding",
    text: "Encoding",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "hddName",
    text: "HDD Name",
    togglable: true,
    visible: true,
    width: 70,
    align: "center",
    sortable: false,
  },
  {
    value: "hddCapacity",
    text: "HDD Capacity",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "freeSpace",
    text: "HDD Free Space",
    togglable: true,
    visible: true,
    width: 90,
    align: "center",
    sortable: false,
  },
  {
    value: "hddStatus",
    text: "HDD Status",
    togglable: true,
    visible: true,
    width: 70,
    align: "center",
    sortable: false,
  },
  {
    value: "hddProperty",
    text: "HDD Property",
    togglable: true,
    visible: true,
    width: 80,
    align: "center",
    sortable: false,
  },
  {
    value: "nvrStatus",
    text: "NVR Status",
    togglable: true,
    visible: true,
    width: 80,
    align: "center",
    sortable: false,
  },
  {
    value: "timeMode",
    text: "Time Mode",
    togglable: true,
    visible: true,
    width: 80,
    align: "center",
    sortable: false,
  },
  {
    value: "localTime",
    text: "Local Time",
    togglable: true,
    visible: true,
    width: 180,
    sortable: false,
  },
  {
    value: "timezone",
    text: "Timezone",
    togglable: true,
    visible: true,
    width: 150,
    sortable: false,
  },
  {
    value: "addressingFormatType",
    text: "NTP Addressing Format Type",
    togglable: true,
    visible: true,
    width: 130,
    align: "center",
    sortable: false,
  },
  {
    value: "hostName",
    text: "NTP Host Name",
    togglable: true,
    visible: true,
    width: 150,
    sortable: false,
  },
  {
    value: "portNo",
    text: "NTP Port No",
    togglable: true,
    visible: true,
    width: 70,
    align: "center",
    sortable: false,
  },
  {
    value: "synchronizeInterval",
    text: "NTP Synchronize Interval",
    togglable: true,
    visible: true,
    width: 120,
    align: "center",
    sortable: false,
  },
]
