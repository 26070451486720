import { NvrHddStatuses } from "@/components/constants"

export default () => {
  return {
    name: {
      component: "TextFieldSearchFilter",
      name: "cameraName",
    },
    exid: {
      component: "TextFieldSearchFilter",
      name: "cameraExid",
    },
    externalHost: {
      component: "TextFieldSearchFilter",
      name: "cameraIp",
    },
    hddStatus: {
      component: "SelectSearchFilter",
      attributes: {
        items: NvrHddStatuses,
        multiple: true,
      },
    },
  }
}
